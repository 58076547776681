import { useNavigate } from "react-router-dom";
import { Header, Li, Nav, Ul } from "../css/styled.layout";

function LayoutHeader({ button }) {
    const navigate = useNavigate();

    const onClickButton = (url) => {
        navigate(url);
    };

    const onClickLogin = () => {
        navigate("/user/login");
    };

    const onClickLogout = () => {
        alert("로그아웃 했습니다");
        navigate("/");
        sessionStorage.clear();
        window.location.reload();
    };

    const naviList = () => {
        const navi = [
            {
                title: "Home",
                url: "/home",
            },
            {
                title: "공지사항",
                url: "/notice",
            },
            {
                title: "함께할개",
                url: "/withthedogs/dog",
            },
            // {
            //     title: "함께했개",
            //     url: "/withthedogs/volunteer",
            // },
        ];

        const naviList = navi.map((item) => (
            <Li key={item.url}>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => onClickButton(item.url)}
                >
                    {item.title}
                </div>
            </Li>
        ));

        naviList.push(
            <Li key={"login"}>
                {sessionStorage.getItem("userId") === null ? (
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={(e) => onClickLogin()}
                    >
                        로그인
                    </div>
                ) : (
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={(e) => onClickLogout()}
                    >
                        로그아웃
                    </div>
                )}
            </Li>,
        );

        return naviList;
    };

    return (
        <Header>
            <Nav>
                <Ul>{naviList()}</Ul>
                {sessionStorage.getItem("userType") === "ADMIN" ? (
                    <Ul>{button}</Ul>
                ) : null}
            </Nav>
        </Header>
    );
}

export default LayoutHeader;
