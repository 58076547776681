import Layout from "../../../component/layout/Layout";
import { Li } from "../../../component/layout/css/styled.layout";
import { useState } from "react";
import { InputBox } from "../../../component/form/Form";
import { useChangeHandler } from "../../../common/CommonUtil";
import { noticeBoardForm } from "./init";
import Editor from "../../../component/board/Editor";
import useBoardService from "../useBoardService";
import { NoticeEditorBoardBox } from "./css/styled.NoticeBoard";
import { Box } from "../../../component/box/css/styled.box";

function NoticeBoardWrite() {
    const { state, onChangeHandler } = useChangeHandler(noticeBoardForm);
    const { postNoticeBoard } = useBoardService();
    const [content, setContent] = useState();

    const onClickWrite = () => {
        state.userId = sessionStorage.getItem("userId");
        state.noticeBoardContent = content;
        postNoticeBoard(state);
    };

    const button = () => {
        return (
            <Li>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => onClickWrite()}
                >
                    저장
                </div>
            </Li>
        );
    };

    return (
        <Layout button={button()}>
            <NoticeEditorBoardBox>
                <Box style={{ padding: "0 0 20px 0" }}>
                    <InputBox
                        label="제목"
                        type="text"
                        width="100%"
                        labelWidth="15%"
                        height="50px"
                        id="noticeBoardTitle"
                        value={state.noticeBoardTitle}
                        onChangeHandler={onChangeHandler}
                    />
                </Box>
                <Editor
                    content={content}
                    setContent={setContent}
                    fileType={"board"}
                />
            </NoticeEditorBoardBox>
        </Layout>
    );
}

export default NoticeBoardWrite;
