import styled from "styled-components";

export const NoticeBoardBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    padding: 0 100px;
    min-height: calc(100vh - 350px);

    @media (max-width: 768px) {
        width: 100%;
        height: 500px;
        padding: 0 20px;
    }
`;

export const NoticeEditorBoardBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    padding: 0 100px;

    @media (max-width: 768px) {
        width: 100%;
        padding: 0 20px;
    }
`;
