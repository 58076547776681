import styled from "styled-components";

export const DogTitleBox = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
`;

export const DogListBox = styled.div`
    display: grid;
    width: 100%;
    min-height: calc(100vh - 400px);
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    padding: 0 200px;
    align-items: center;

    @media (max-width: 768px) {
        display: flex;
        min-height: calc(100vh - 500px);
        padding: 0 30px;
        margin-bottom: 30px;
        flex-direction: column;
        align-items: center;
    }
`;

export const DogMainBox = styled.div`
    display: block;
    min-height: calc(100vh - 100px);
    align-content: center;

    @media (max-width: 768px) {
        display: inline;
        height: 100%;
    }
`;

export const DogBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    width: 250px;
    height: 350px;
    align-items: center;
    border: 1px solid ${(props) => props.color || "#ccc"};
    border-radius: 20px;

    @media (max-width: 768px) {
        display: flex;
        width: 300px;
        height: 120px;
    }
`;

export const DogLeftBox = styled.div`
    @media (max-width: 768px) {
        width: 120px;
    }
`;

export const DogRightBox = styled.div`
    @media (max-width: 768px) {
        width: 180px;
    }
`;

export const DogImageBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: block;
    width: 220px;
    height: 220px;
    margin: 10px auto;
    border: 1px solid #ccc;
    border-radius: 20px;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        display: flex;
        width: 100px;
        height: 100px;
    }
`;

export const DogNameBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: flex;
    height: 30px;
    justify-content: center;
    align-items: center;
`;

export const LikePeopleBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: flex;
    height: 30px;
    justify-content: center;
    align-items: center;
`;

export const DogButtonBox = styled.div`
    display: flex;
`;
