import { useState } from "react";
import {
    DogProfileModalBackgroundBox,
    DogProfileModalBottomButtonBox,
    DogProfileModalBox,
    DogProfileModalButtonBox,
    DogProfileModalCloseButtonBox,
    DogProfileModalImage,
    DogProfileModalImageBox,
} from "./css/styled.DogProfileModal";
import useDogsService from "./useDogsService";

function DogProfileModal({
    isModal,
    setIsModal,
    imageUrl,
    index,
    dogImageList,
}) {
    const totalCount = dogImageList.length - 1;

    const [count, setCount] = useState(totalCount);
    const [newIndex, setNewIndex] = useState(index);
    const [url, setUrl] = useState(imageUrl);

    const onClickClose = () => {
        setIsModal(!isModal);
    };

    const onClickPrev = () => {
        const beforeIndex = newIndex - 1;
        const beforeImageUrl = dogImageList[beforeIndex].fileUrl;
        setNewIndex(beforeIndex);
        setUrl(beforeImageUrl);
    };

    const onClickNext = () => {
        const nextIndex = newIndex + 1;
        const nextImageUrl = dogImageList[nextIndex].fileUrl;
        setNewIndex(nextIndex);
        setUrl(nextImageUrl);
    };

    const { deleteDogImage } = useDogsService();
    const onClickDelete = () => {
        const query = {
            shelterCode: dogImageList[newIndex].shelterCode,
            dogCode: dogImageList[newIndex].dogCode,
            fileUuid: dogImageList[newIndex].fileUuid,
        };

        deleteDogImage({ query });
    };

    if (isModal) {
        return (
            <DogProfileModalBox>
                <DogProfileModalBackgroundBox>
                    <DogProfileModalButtonBox>
                        <DogProfileModalCloseButtonBox
                            onClick={() => onClickDelete()}
                        >
                            삭제
                        </DogProfileModalCloseButtonBox>
                        <DogProfileModalCloseButtonBox
                            onClick={() => onClickClose()}
                        >
                            닫기
                        </DogProfileModalCloseButtonBox>
                    </DogProfileModalButtonBox>
                    <DogProfileModalImageBox>
                        <DogProfileModalImage>
                            <img
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "20px",
                                }}
                                src={url}
                            />
                        </DogProfileModalImage>
                    </DogProfileModalImageBox>
                    <DogProfileModalBottomButtonBox>
                        {newIndex === 0 ? (
                            <div
                                style={{
                                    display: "flex",
                                    width: "100px",
                                    justifyContent: "center",
                                    textAlign: "center",
                                }}
                            >
                                ◁ <br /> 이전
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    width: "100px",
                                    justifyContent: "center",
                                    textAlign: "center",
                                }}
                                onClick={() => onClickPrev()}
                            >
                                ◀ <br /> 이전
                            </div>
                        )}
                        <div
                            style={{
                                display: "flex",
                                width: "150px",
                                justifyContent: "center",
                            }}
                        >
                            ({newIndex + 1} / {totalCount + 1})
                        </div>
                        {count === newIndex ? (
                            <div
                                style={{
                                    display: "flex",
                                    width: "100px",
                                    justifyContent: "center",
                                    textAlign: "center",
                                }}
                            >
                                ▷ <br /> 다음
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    width: "100px",
                                    justifyContent: "center",
                                    textAlign: "center",
                                }}
                                onClick={() => onClickNext()}
                            >
                                ▶ <br /> 다음
                            </div>
                        )}
                    </DogProfileModalBottomButtonBox>
                </DogProfileModalBackgroundBox>
            </DogProfileModalBox>
        );
    } else {
        return null;
    }
}

export default DogProfileModal;
