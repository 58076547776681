import { useNavigate } from "react-router-dom";
import Layout from "../../../component/layout/Layout";
import {
    DogBox,
    DogImageBox,
    DogLeftBox,
    DogListBox,
    DogNameBox,
    DogRightBox,
    DogTitleBox,
    LikePeopleBox,
} from "./css/styled.Dog";
import useDogsService from "./useDogsService";
import { useEffect, useState } from "react";
import { Box } from "../../../component/box/css/styled.box";
import { Li } from "../../../component/layout/css/styled.layout";
import Pagination from "../../../component/pagination/Pagination";

function Dog() {
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState();
    const [dogList, setDogList] = useState([]);
    const { getDogList } = useDogsService();

    useEffect(() => {
        const query = {
            page: currentPage - 1,
            size: 5,
        };

        getDogList({ query, setDogList, setPage });
    }, [currentPage]);

    const onChagePage = (page) => {
        setCurrentPage(page);
    };

    const onClickNew = () => {
        navigate(`/withthedogs/dog/new`);
    };

    const onClickDog = (shelterCode, dogCode) => {
        navigate(`/withthedogs/dog/${shelterCode}/${dogCode}`);
    };

    const likePeople = (likePeople) => {
        let likePeopleArray = [];

        for (let i = 0; i < 5; i++) {
            if (likePeople > i) {
                likePeopleArray.push(<span key={i}>♥</span>);
            } else {
                likePeopleArray.push(<span key={i}>♡</span>);
            }
        }

        return likePeopleArray;
    };

    const button = () => {
        return (
            <Li>
                <div style={{ cursor: "pointer" }} onClick={onClickNew}>
                    등록
                </div>
            </Li>
        );
    };

    return (
        <Layout button={button()}>
            <DogTitleBox>♥ 만큼 사람을 좋아합니다</DogTitleBox>
            <DogListBox>
                {dogList.map((item) => (
                    <DogBox
                        key={item.dogCode}
                        color={item.dogLeaveYn === "N" ? null : "red"}
                        onClick={() =>
                            onClickDog(item.shelterCode, item.dogCode)
                        }
                    >
                        <DogLeftBox>
                            <DogImageBox>
                                {item.dogImageJson !== undefined ? (
                                    <img
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "20px",
                                            overflow: "hidden",
                                            objectFit: "cover",
                                        }}
                                        src={item.dogImageJson.fileUrl}
                                        alt={item.dogImageJson.fileName}
                                    />
                                ) : (
                                    <Box
                                        style={{
                                            display: "flex",
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "20px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        이미지 없음
                                    </Box>
                                )}
                            </DogImageBox>
                        </DogLeftBox>
                        <DogRightBox>
                            <DogNameBox>{item.dogName}</DogNameBox>
                            <LikePeopleBox>
                                {likePeople(item.likePeople)}
                            </LikePeopleBox>
                            <DogNameBox>{item.shelterName}</DogNameBox>
                        </DogRightBox>
                    </DogBox>
                ))}
            </DogListBox>
            {page && (
                <Pagination
                    currentPage={currentPage}
                    totalElements={page.totalElements}
                    onChangePage={onChagePage}
                />
            )}
        </Layout>
    );
}

export default Dog;
